import * as $ from 'jquery'
import 'flowbite'
import '../styles/styles.css'

import(/* webpackChunkName: "navbar" */ './navbar')

switch (jsPage) {
  case 'login':
    import(/* webpackChunkName: "login" */ './login')
    break
  case 'password-reset':
    import(/* webpackChunkName: "password-reset" */ './password-reset')
    break
  case 'vehicleView':
    import(/* webpackChunkName: "vehicle" */ './vehicle')
    break
  case 'info':
    import(/* webpackChunkName: "info" */ './info')
    break
  case 'account':
    import(/* webpackChunkName: "account" */ './account')
    break
  case 'support':
    import(/* webpackChunkName: "support" */ './support')
    break
  case 'renewals':
    import(/* webpackChunkName: "renewals" */ './renewals')
    break
  default:
    console.log('no page js loaded')
    break
}

$(document).ready(function() {
  $('#vehicleChange').change(() => {
    window.location.href = window.location.origin + '/vehicle/' + $('#vehicleChange').val()
  })
})
